import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export default function ContactForm() {
    // email.js
    // https://www.emailjs.com/docs/examples/reactjs/
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        const sendingSVG = document.getElementById("sending-svg");
        const sendButton = document.getElementById("send-button");
        const sendButtonText = document.getElementById("send-button-text");
        const nameInput = document.getElementById("name-input");
        const emailInput = document.getElementById("email-input");
        const messageInput = document.getElementById("message-input");

        let validForm = true;
        [nameInput, emailInput, messageInput].forEach(element => {
            if (element.value.trim() === "") {
                element.classList.add("missing-field")
                validForm = false;
            }
        })

        if (emailInput.value.trim().toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) === null) {
            emailInput.classList.add("missing-field")
            validForm = false;
        }
        
        if (!validForm) return;
        sendingSVG.classList.remove("hidden");
        sendButton.setAttribute("disabled", true)

        setTimeout(() => {
            emailjs.sendForm('service_3n7a3jk', 'template_g0tikhe', form.current, 'zVDFATTilRkuqhCMS')
                .then((result) => {
                    if (result.text === "OK") {
                        sendingSVG.classList.add("hidden");
                        sendButton.classList.add("bg-[#6bd170]");
                        sendButton.classList.add("dark:bg-[#6bd170]");
                        sendButton.classList.add("dark:text-white");
                        sendButtonText.innerHTML = "Sent"
                        setTimeout(() => {
                            sendButton.classList.remove("bg-[#6bd170]")
                            sendButton.classList.remove("dark:bg-[#6bd170]")
                            sendButton.classList.remove("dark:text-white");
                            sendButtonText.innerHTML = "Send"
                            sendButton.removeAttribute("disabled");
                        }, 1500);
                    } else {
                        sendingSVG.classList.add("hidden");
                        sendButton.classList.add("bg-[#db4f4f]");
                        sendButton.classList.add("dark:bg-[#db4f4f]");
                        sendButton.classList.add("dark:text-white");
                        sendButtonText.innerHTML = "Failed to Send"
                        setTimeout(() => {
                            sendButton.classList.remove("bg-[#db4f4f]")
                            sendButton.classList.remove("dark:bg-[#db4f4f]")
                            sendButton.classList.remove("dark:text-white");
                            sendButtonText.innerHTML = "Send"
                            sendButton.removeAttribute("disabled");
                        }, 1500);
                    }
                }, (error) => {
                    sendingSVG.classList.add("hidden");
                    sendButton.classList.add("bg-[#db4f4f]");
                    sendButton.classList.add("dark:bg-[#db4f4f]");
                    sendButton.classList.add("text-white");
                    sendButton.classList.add("dark:text-white");
                    sendButtonText.innerHTML = "Failed to Send"
                    setTimeout(() => {
                        sendButton.classList.remove("bg-[#db4f4f]")
                        sendButton.classList.remove("dark:bg-[#db4f4f]")
                        sendButton.classList.remove("text-white");
                        sendButton.classList.remove("dark:text-white");
                        sendButtonText.innerHTML = "Send"
                        sendButton.removeAttribute("disabled");
                    }, 1500);
                });
        }, 500);
    };

    return (
        <form ref={form} onSubmit={sendEmail} className="flex flex-col mt-6 py-[50px] w-full max-w-[1100px] bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] rounded shadow-xl px-6 h-full">
            <label className='text-[#030711] dark:text-white mt-4 mb-1 text-lg sm:text-base'>Name</label>
            <input id='name-input' className='text-[#030711] bg-[rgba(10,14,24,.05)] border-[1px] border-[#98999f5b] dark:bg-[#32384921] dark:text-white bg w-full rounded p-2' type='text' name='user_name' onFocus={()=>{document.getElementById("name-input").classList.remove("missing-field")}}></input>
            <label className='text-[#030711] dark:text-white mt-4 mb-1 text-lg sm:text-base'>Email</label>
            <input id='email-input' className='text-[#030711] bg-[rgba(10,14,24,.05)] border-[1px] border-[#98999f5b] dark:bg-[#32384921] dark:text-white bg w-full rounded p-2' type='text' name='user_email' onFocus={()=>{document.getElementById("email-input").classList.remove("missing-field")}}></input>
            <label className='text-[#030711] dark:text-white mt-4 mb-1 text-lg sm:text-base'>Message</label>
            <textarea id='message-input' className='text-[#030711] bg-[rgba(10,14,24,.05)] border-[1px] border-[#98999f5b] dark:bg-[#32384921] dark:text-white bg w-full rounded p-2 resize-none' rows={10} name='message' onFocus={()=>{document.getElementById("message-input").classList.remove("missing-field")}}></textarea>
            <button id='send-button' className='flex items-center bg-[#030711] dark:bg-white transition-colors duration-200 text-white dark:text-[#030711] rounded shadow-lg mt-4 ml-auto py-2 px-6' type='submit' value="Send">
                <p id='send-button-text'>Send</p>
                <svg id='sending-svg' className='hidden animate-spin fill-white dark:fill-[#030711] w-4 h-4 ml-2' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512"><path d="M12,24A12,12,0,1,1,22.714,6.59a1,1,0,1,1-1.785.9,10,10,0,1,0-.011,9.038,1,1,0,0,1,1.781.908A11.955,11.955,0,0,1,12,24Z"/></svg>
            </button>
        </form>
    )
}