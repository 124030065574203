import beauty from "../assets/beauty.png"
import pet from "../assets/pet.png"
import cooking from "../assets/cooking.png"
import health from "../assets/health.png"
import diy from "../assets/diy.png"
import home from "../assets/home-products.png"


export default function Products() {
    return (
        <>
            <p className="text-[#030711] dark:text-white text-center text-3xl font-bold pt-[200px] sm:pt-[75px] pb-[50px] sm:text-2xl">Products</p>
            <div className="relative flex">
                <div className="grid grid-cols-2 grid-rows-2 lg:grid-rows-1 lg:grid-cols-1 w-[80%] pb-[200px] max-w-[1100px] gap-y-8 gap-x-8 mx-auto">
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[#03071108] border-[1px] dark:border-[#ffffff1a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] max-w-[500px] mx-auto gap-x-5 p-6 rounded-xl shadow-xl">
                        <img className="w-[300px] h-[300px] sm:w-[250px] sm:h-[250px] rounded-xl shadow-xl dark:shadow-none mx-auto mb-4" src={beauty} alt=""></img>
                        <div className="flex flex-col mb-auto">
                            <p className="text-[#030711] dark:text-white text-center text-2xl my-3">Beauty</p>
                            <p className="text-[#030711] dark:text-white text-center text-lg mx-[20px] mb-auto sm:text-base">We offer a wide range of beauty care products at competitive prices. From body lotions to hair curlers, we have top quality products from amazing manufacturers</p>
                        </div>
                    </div>                
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[#03071108] border-[1px] dark:border-[#ffffff1a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] max-w-[500px] mx-auto gap-x-5 p-6 rounded-xl shadow-xl">
                        <img className="w-[300px] h-[300px] sm:w-[250px] sm:h-[250px] rounded-xl shadow-xl dark:shadow-none mx-auto mb-4" src={health} alt=""></img>
                        <div className="flex flex-col mb-auto">
                            <p className="text-[#030711] dark:text-white text-center text-2xl my-3">Health</p>
                            <p className="text-[#030711] dark:text-white text-center text-lg mx-[20px] mb-auto sm:text-base">Offering top quality beauty products we also provide the best quality of health products two times the size of our beauty catalog</p>
                        </div>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[#03071108] border-[1px] dark:border-[#ffffff1a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] max-w-[500px] mx-auto gap-x-5 p-6 rounded-xl shadow-xl">
                        <img className="w-[300px] h-[300px] sm:w-[250px] sm:h-[250px] rounded-xl shadow-xl dark:shadow-none mx-auto mb-4" src={pet} alt=""></img>
                        <div className="flex flex-col mb-auto">
                            <p className="text-[#030711] dark:text-white text-center text-2xl my-3">Pet Supplies & Treats</p>
                            <p className="text-[#030711] dark:text-white text-center text-lg mx-[20px] mb-auto sm:text-base">Offering a large number of pet products, we have products ranging from foods to toys for any pet needs</p>
                        </div>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[#03071108] border-[1px] dark:border-[#ffffff1a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] max-w-[500px] mx-auto gap-x-5 p-6 rounded-xl shadow-xl">
                        <img className="w-[300px] h-[300px] sm:w-[250px] sm:h-[250px] rounded-xl shadow-xl dark:shadow-none mx-auto mb-4" src={cooking} alt=""></img>
                        <div className="flex flex-col mb-auto">
                            <p className="text-[#030711] dark:text-white text-center text-2xl my-3">Cooking Supplies</p>
                            <p className="text-[#030711] dark:text-white text-center text-lg mx-[20px] mb-auto sm:text-base">Select products that will help improve those cooking habits or professional cooks.</p>
                        </div>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[#03071108] border-[1px] dark:border-[#ffffff1a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] max-w-[500px] mx-auto gap-x-5 p-6 rounded-xl shadow-xl">
                        <img className="w-[300px] h-[300px] sm:w-[250px] sm:h-[250px] rounded-xl shadow-xl dark:shadow-none mx-auto mb-4" src={diy} alt=""></img>
                        <div className="flex flex-col mb-auto">
                            <p className="text-[#030711] dark:text-white text-center text-2xl my-3">DIY Crafts</p>
                            <p className="text-[#030711] dark:text-white text-center text-lg mx-[20px] mb-auto sm:text-base">We have a range of items for quality DIY products for workers that may need it in their field of craft.</p>
                        </div>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[#03071108] border-[1px] dark:border-[#ffffff1a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] max-w-[500px] mx-auto gap-x-5 p-6 rounded-xl shadow-xl">
                        <img className="w-[300px] h-[300px] sm:w-[250px] sm:h-[250px] rounded-xl shadow-xl dark:shadow-none mx-auto mb-4" src={home} alt=""></img>
                        <div className="flex flex-col mb-auto">
                            <p className="text-[#030711] dark:text-white text-center text-2xl my-3">Home Improvement</p>
                            <p className="text-[#030711] dark:text-white text-center text-lg mx-[20px] mb-auto sm:text-base">A range of items that will imrpove the health of your home, ranging from cleaning supplies to decorational items we offer quality home products.</p>
                        </div>
                    </div>
                </div>
                <div className="dots absolute z-[-1] w-[400px] h-[300px] sm:w-[200px] sm:h-[175px] top-[-1%] right-[6%]"></div>
                <div className="dots absolute z-[-1] w-[300px] h-[350px] sm:w-[175px] sm:h-[200px] top-[30%] left-[6%]"></div>
                <div className="absolute z-[-1] opacity-0 w-[100px] h-[100px] sm:w-14 sm:h-14 rounded-full bg-[#03071115] dark:bg-[#ffffff15] top-[25%] left-[-1%] animate-bubble-in2"></div>
                <div className="absolute z-[-1] w-11 h-11 sm:w-5 sm:h-5 rounded-full bg-[#03071115] dark:bg-[#ffffff25] bottom-[20%] left-[-3%] animate-bubble-in1"></div>
                <div className="absolute z-[-1] opacity-0 w-[350px] h-[350px] sm:w-[300px] sm:h-[300px] rounded-full bg-[#03071110] dark:bg-[#ffffff15] top-[-3%] right-[0%] animate-bubble-in4"></div>
                <div className="absolute z-[-1] opacity-0 w-[175px] h-[175px] sm:w-[150px] sm:h-[150px] rounded-full bg-[#03071115] dark:bg-[#ffffff25] top-[45%] right-[5%] animate-bubble-in3"></div>
                <div className="absolute z-[-1] opacity-0 w-[175px] h-[175px] sm:w-[120px] sm:h-[120px] rounded-full bg-[#03071115] dark:bg-[#ffffff25] bottom-[10%] left-[0%] animate-bubble-in2"></div>
            </div>
        </>
    )
}