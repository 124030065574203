import { Link } from "react-router-dom";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="flex flex-col pb-4 mx-auto w-[80%] max-w-[1100px] sm:max-w-[550px]">
            <div className="flex">
                <a className="flex items-center hover mb-auto sm:hidden" href="/">
                    <svg className="w-5 h-5 stroke-[#030711] dark:stroke-white" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"> <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line> <path d="M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"></path> <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline> <line x1="12" y1="22.08" x2="12" y2="12"></line> </svg>
                    <p className="mx-2 text-sm text-[#030711] dark:text-white ">WCitySeller</p>
                </a>
                <div className="flex flex-col ml-2 sm:ml-0 text-sm">
                    <Link className="text-[#6e6f74] my-[2px] mx-3 sm:mx-0 text-sm sm:text-xs" to={"/"} reloadDocument>Home</Link>      
                    <Link className="text-[#6e6f74] my-[2px] mx-3 sm:mx-0 text-sm sm:text-xs" to={"/about"} reloadDocument>About</Link>      
                    <Link className="text-[#6e6f74] my-[2px] mx-3 sm:mx-0 text-sm sm:text-xs" to={"/products"} reloadDocument>Products</Link>      
                    <Link className="text-[#6e6f74] my-[2px] mx-3 sm:mx-0 text-sm sm:text-xs" to={"/contact"} reloadDocument>Contact</Link>      
                </div>
                <div className="flex flex-col ml-auto">
                    <p className="text-[#030711] dark:text-white text-right sm:text-sm">Contact Information</p>
                    <p className="text-[#6e6f74] text-sm underline text-right my-[3px] cursor-pointer sm:text-xs" onClick={()=>{window.location.href = "mailto:support@wcityseller.com"}}>support@wcityseller.com</p>
                    <p className="text-[#6e6f74] text-sm text-right sm:text-xs">+1 (872) 325 3169</p>
                </div>
            </div>
            <p className="text-[#7878837a] pt-[4px] mt-6 text-xs sm:text-[11px]">{`Copyright @${currentYear} WCitySeller`}</p>
        </footer>
    )   
}
