import ScrollObserver from "../hooks/ScrollObserver";
import ContactForm from "../components/ContactForm"

export default function Contact() {
    ScrollObserver();
    return (
        <>
            {/* top */}
            <div className="flex flex-col py-[200px] sm:py-[75px] w-[80%] max-w-[1100px]">
                <p className="text-[#030711] dark:text-white font-bold text-3xl sm:text-2xl ">Have a question?</p>
                <p className="text-[#030711] dark:text-white font-bold text-3xl my-2 sm:text-2xl">Get in touch with us 👋</p>
                <div className="grid grid-rows-1 grid-cols-3 lg:hidden mt-8 gap-x-6 xl:gap-x-4 gap-y-4">
                    <div id="card-contact" className="invisible flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl xl:text-lg my-4">Phone</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] xl:text-base mx-6">(872)-325-3169</p>
                    </div>
                    <div id="card-contact" className="invisible flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl xl:text-lg my-4">Email</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] xl:text-base mx-6 underline cursor-pointer" onClick={()=>{window.location.href = "mailto:support@wcityseller.com"}}>support@wcityseller.com</p>
                    </div>
                    <div id="card-contact" className="invisible flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 96a80 80 0 10-96 78.39v283.17a32.09 32.09 0 002.49 12.38l10.07 24a3.92 3.92 0 006.88 0l10.07-24a32.09 32.09 0 002.49-12.38V174.39A80.13 80.13 0 00336 96zm-56 0a24 24 0 1124-24 24 24 0 01-24 24z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl xl:text-lg my-4">Location</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] xl:text-base mx-6">Chicago, Illinois</p>
                    </div>
                </div>
                <div className="hidden lg:flex flex-col mt-8 gap-y-4">
                    <div className="flex">
                        <svg className="fill-[030711e1] dark:fill-white w-14 h-14 p-2 sm:h-12 sm:w-12 sm:my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M391 480c-19.52 0-46.94-7.06-88-30-49.93-28-88.55-53.85-138.21-103.38C116.91 298.77 93.61 267.79 61 208.45c-36.84-67-30.56-102.12-23.54-117.13C45.82 73.38 58.16 62.65 74.11 52a176.3 176.3 0 0128.64-15.2c1-.43 1.93-.84 2.76-1.21 4.95-2.23 12.45-5.6 21.95-2 6.34 2.38 12 7.25 20.86 16 18.17 17.92 43 57.83 52.16 77.43 6.15 13.21 10.22 21.93 10.23 31.71 0 11.45-5.76 20.28-12.75 29.81-1.31 1.79-2.61 3.5-3.87 5.16-7.61 10-9.28 12.89-8.18 18.05 2.23 10.37 18.86 41.24 46.19 68.51s57.31 42.85 67.72 45.07c5.38 1.15 8.33-.59 18.65-8.47 1.48-1.13 3-2.3 4.59-3.47 10.66-7.93 19.08-13.54 30.26-13.54h.06c9.73 0 18.06 4.22 31.86 11.18 18 9.08 59.11 33.59 77.14 51.78 8.77 8.84 13.66 14.48 16.05 20.81 3.6 9.53.21 17-2 22-.37.83-.78 1.74-1.21 2.75a176.49 176.49 0 01-15.29 28.58c-10.63 15.9-21.4 28.21-39.38 36.58A67.42 67.42 0 01391 480z"></path></svg>
                        <div className="my-auto ml-3">
                            <p className="text-[#030711] dark:text-white text-lg">Email</p>
                            <p className="text-[#808188] underline cursor-pointer" onClick={()=>{window.location.href = "mailto:support@wcityseller.com"}}>support@wcityseller.com</p>
                        </div>
                    </div>
                    <div className="flex">
                        <svg className="fill-[#030711e1] dark:fill-white w-14 h-14 p-2 sm:h-12 sm:w-12 sm:my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M424 80H88a56.06 56.06 0 00-56 56v240a56.06 56.06 0 0056 56h336a56.06 56.06 0 0056-56V136a56.06 56.06 0 00-56-56zm-14.18 92.63l-144 112a16 16 0 01-19.64 0l-144-112a16 16 0 1119.64-25.26L256 251.73l134.18-104.36a16 16 0 0119.64 25.26z"></path></svg>
                        <div className="my-auto ml-3">
                            <p className="text-[#030711] dark:text-white text-lg">Phone</p>
                            <p className="text-[#808188]">(871)-325-3169</p>
                        </div>
                    </div>
                    <div className="flex">
                        <svg className="fill-[#030711e1] dark:fill-white w-14 h-14 p-2 sm:h-12 sm:w-12 sm:my-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M336 96a80 80 0 10-96 78.39v283.17a32.09 32.09 0 002.49 12.38l10.07 24a3.92 3.92 0 006.88 0l10.07-24a32.09 32.09 0 002.49-12.38V174.39A80.13 80.13 0 00336 96zm-56 0a24 24 0 1124-24 24 24 0 01-24 24z"></path></svg>
                        <div className="my-auto ml-3">
                            <p className="text-[#030711] dark:text-white text-lg">Location</p>
                            <p className="text-[#808188]">Chicago, Illinois</p>
                        </div>
                    </div>
                </div>
                {/* contact form */}
                <p className="text-[#030711] dark:text-white font-bold text-3xl mt-14 sm:text-2xl">Leave us a message!</p>
                <div className="relative">
                    <ContactForm></ContactForm>
                    <div className="dots absolute z-[-1] w-[400px] h-[300px] sm:w-[175px] sm:h-[175px] top-[-2%] right-[-3%] sm:top-[0%] sm:right-[-5%]"></div>
                    <div className="absolute z-[-1] opacity-0 w-[100px] h-[100px] sm:w-14 sm:h-14 rounded-full bg-[#03071115] dark:bg-[#ffffff15] top-[-2%] left-[-4%] sm:top-[-17px] sm:left-[-5%] animate-bubble-in2"></div>
                    <div className="absolute z-[-1] w-11 h-11 sm:w-5 sm:h-5 rounded-full bg-[#03071115] dark:bg-[#ffffff25] bottom-[20%] left-[-7%] sm:bottom-[20%] sm:left-[-3%] animate-bubble-in1"></div>
                    <div className="absolute z-[-1] opacity-0 w-[350px] h-[350px] sm:w-[300px] sm:h-[300px] rounded-full bg-[#03071110] dark:bg-[#ffffff15] top-[0%] right-[-10%] sm:top-[-50px] sm:right-[-20%] animate-bubble-in4"></div>
                    <div className="absolute z-[-1] opacity-0 w-[175px] h-[175px] sm:w-[150px] sm:h-[150px] rounded-full bg-[#03071115] dark:bg-[#ffffff25] top-[45%]  right-[-5%] sm:top-[30%] sm:right-[-7%] animate-bubble-in3"></div>
                    <div className="absolute z-[-1] opacity-0 w-[175px] h-[175px] sm:w-[120px] sm:h-[120px] rounded-full bg-[#03071115] dark:bg-[#ffffff25] bottom-[-5%]  left-[-5%] sm:bottom-[-2%] sm:left-[-7%] animate-bubble-in2"></div>
                </div>
            </div>
        </>
    )
}
