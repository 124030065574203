export default function ScrollObserver() {
    const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                entry.target.classList.add("show");
                entry.target.classList.remove("invisible");
            }
        })
    })

    setTimeout(() => {
        const hiddenElements = document.getElementsByClassName("invisible");
        Array.from(hiddenElements).forEach(element => {observer.observe(element)});
    }, 250);

    return null;
}