export default function About() {
    return (
        <>
            <div className="flex flex-col pt-[250px] pb-[200px] sm:py-[100px] w-[80%] max-w-[1100px] xl:max-w-[550px]">
                <p className="text-[#030711] dark:text-white text-3xl sm:text-2xl font-bold text-center mb-4">Our Mission</p>
                <p className="text-[#98999f] text-lg sm:text-base text-center">Here at WCitySeller, we take great pride in our commitment to quality and excellence. We work closely with our suppliers to ensure that every product we offer meets our high standards for durability, functionality, and design. Our team of experts carefully selects each product before making it available to our customers, ensuring that only the best products are offered on our platform.</p>
            </div>       
            <p className="text-[#030711] dark:text-white text-xl font-bold text-center mb-4">SERVICES</p>
            <div className="flex pt-[25px] pb-[150px] w-[70%] max-w-[1000px]">
                <div className="relative grid grid-rows-2 grid-cols-2 xl:grid-rows-1 xl:grid-cols-1 gap-6 xl:max-w-[500px] mx-auto w-full h-fit">
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M332.64 64.58C313.18 43.57 286 32 256 32c-30.16 0-57.43 11.5-76.8 32.38-19.58 21.11-29.12 49.8-26.88 80.78C156.76 206.28 203.27 256 256 256s99.16-49.71 103.67-110.82c2.27-30.7-7.33-59.33-27.03-80.6zM432 480H80a31 31 0 01-24.2-11.13c-6.5-7.77-9.12-18.38-7.18-29.11C57.06 392.94 83.4 353.61 124.8 326c36.78-24.51 83.37-38 131.2-38s94.42 13.5 131.2 38c41.4 27.6 67.74 66.93 76.18 113.75 1.94 10.73-.68 21.34-7.18 29.11A31 31 0 01432 480z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl sm:text-lg my-4">Customer Support</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mx-6">Our team of friendly and knowledgeable customer support representatives is always available to assist our customers with any questions or concerns, ensuring that they have the support they need to succeed.</p>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16 352a48.05 48.05 0 0048 48h133.88l-4 32H144a16 16 0 000 32h224a16 16 0 000-32h-49.88l-4-32H448a48.05 48.05 0 0048-48v-48H16zm240-16a16 16 0 11-16 16 16 16 0 0116-16zM496 96a48.05 48.05 0 00-48-48H64a48.05 48.05 0 00-48 48v192h480z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl sm:text-lg my-4">User-Friendly Website</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mx-6">We offer a seamless shopping experience with our easy-to-use website. Designed to be user-friendly, allowing our customers to easily find the products they need.</p>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M200 144h40v-40a40 40 0 10-40 40zM352 104a40 40 0 00-80 0v40h40a40 40 0 0040-40z" fill="none"></path><path d="M80 416a64 64 0 0064 64h92a4 4 0 004-4V292a4 4 0 00-4-4H88a8 8 0 00-8 8zM240 252V144h32v108a4 4 0 004 4h140a47.93 47.93 0 0016-2.75A48.09 48.09 0 00464 208v-16a48 48 0 00-48-48h-40.54a2 2 0 01-1.7-3A72 72 0 00256 58.82 72 72 0 00138.24 141a2 2 0 01-1.7 3H96a48 48 0 00-48 48v16a48.09 48.09 0 0032 45.25A47.93 47.93 0 0096 256h140a4 4 0 004-4zm32-148a40 40 0 1140 40h-40zm-74.86-39.9A40 40 0 01240 104v40h-40a40 40 0 01-2.86-79.89zM276 480h92a64 64 0 0064-64V296a8 8 0 00-8-8H276a4 4 0 00-4 4v184a4 4 0 004 4z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl sm:text-lg my-4">Quality Products</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mx-6">Working closely with our suppliers to ensure that every product we offer meets our high standards for durability, functionality, and design. Our team of experts carefully selects each product before making it available to our customers, ensuring that only the best products are offered on our platform.</p>
                    </div>
                    <div className="flex flex-col bg-white dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl py-8 sm:py-6 px-4 h-full w-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2 mx-auto" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M121.72 32a4 4 0 00-3.72 5.56l2.3 5.43 40.7 94.9a4 4 0 006.88.82L243 38.4a4 4 0 00-3.2-6.4zM419.93 58.06l-41.28 96.37a4 4 0 003.68 5.57h101a4 4 0 003.4-6.11L427 57.53a4 4 0 00-7.07.53zM85 57.57l-59.71 96.32a4 4 0 003.4 6.11h101a4 4 0 003.67-5.58L92 58.1a4 4 0 00-7-.53zM393.27 32H267.82a1.94 1.94 0 00-1.56 3.11l79.92 106.46a1.94 1.94 0 003.34-.4L391.6 43l3.4-8.34a1.92 1.92 0 00-1.7-2.66zM239 448l-89.43-253.49A3.78 3.78 0 00146 192H25.7a3.72 3.72 0 00-2.95 6l216 279.81a5.06 5.06 0 006.39 1.37 5 5 0 002.39-6.08zM486.3 192H366a3.75 3.75 0 00-3.54 2.51l-98.2 278.16a5.21 5.21 0 002.42 6.31 5.22 5.22 0 006.61-1.39L489.25 198a3.72 3.72 0 00-2.95-6zM259.2 78.93l56 74.67a4 4 0 01-3.2 6.4H200a4 4 0 01-3.2-6.4l56-74.67a4 4 0 016.4 0zm-7 310.31l-67.7-191.91a4 4 0 013.77-5.33h135.46a4 4 0 013.77 5.33l-67.73 191.91a4 4 0 01-7.54 0z"></path></svg>
                        <p className="text-center text-[#030711] dark:text-white text-xl sm:text-lg my-4">Product Variety</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mx-6">Dedicated to providing our customers with an extensive selection of products across various categories, including electronics, clothing, accessories, home goods, and much more.</p>
                    </div>
                    <div className="dots absolute z-[-1] w-[350px] h-[350px] sm:w-[175px] xl:h-[250px] top-[-3%] right-[-4.5%] xl:top-[-2%] xl:right-[-7%] sm:top-[-1.5%] sm:right-[-6%]"></div>
                    <div className="dots absolute z-[-1] w-[250px] h-[200px] sm:w-[175px] sm:h-[175px] bottom-[-25px] left-[-25px]"></div>
                    <div className="absolute z-[-1] w-11 h-11 sm:w-8 sm:h-8 rounded-full bg-[#03071115] dark:bg-[#ffffff25] top-[20%] right-[-12%] sm:top-[80%] sm:left-[-10%] animate-bubble-in1"></div>
                    <div className="absolute z-[-1] opacity-0 w-[100px] h-[100px] sm:w-[100px] sm:h-[100px] rounded-full bg-[#03071115] dark:bg-[#ffffff15] top-[30%] right-[-10%] sm:top-[25%] sm:right-[-15%] animate-bubble-in2"></div>
                    <div className="absolute z-[-1] opacity-0 w-[150px] h-[150px] sm:w-14 sm:h-14 rounded-full bg-[#03071115] dark:bg-[#ffffff15] bottom-[-10%] right-[5%] sm:bottom-[-17px] sm:right-[-5%] animate-bubble-in2"></div>
                    <div className="absolute z-[-1] opacity-0 w-[200px] h-[200px] sm:w-[150px] sm:h-[150px] rounded-full bg-[#03071115] dark:bg-[#ffffff25] top-[35%] left-[-10%] sm:top-[7%] sm:left-[-8%] animate-bubble-in3"></div>
                    <div className="absolute z-[-1] opacity-0 w-[300px] h-[300px] sm:w-[200px] sm:h-[200px] rounded-full bg-[#03071110] dark:bg-[#ffffff15] top-[5%] left-[-17%] sm:top-[-50px] sm:left-[-20%] animate-bubble-in4"></div>
                </div>
            </div>
        </>
    )
}