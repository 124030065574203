import home from "../assets/home.png"
import { Link } from "react-router-dom";
import ScrollObserver from "../hooks/ScrollObserver";

export default function Home() {
    return (
        <>
            {/* landing splash */}
            <ScrollObserver></ScrollObserver>
            <div className="flex py-[150px] sm:py-[75px] w-[80%] max-w-[1100px]">
                <div className="flex flex-row-reverse xl:flex-col gap-x-10 xl:mx-auto">
                    <div className="relative z-[-1]">
                        <img className="bg-white rounded px-[100px] sm:px-[50px] sm:w-[100%] sm:max-w-[400px] max-w-[550px] max-h-[350px] sm:mx-auto shadow-xl dark:shadow-none" src={home} alt="home"></img>
                        <div className="dots absolute z-[-1] w-[400px] h-[300px] sm:w-[175px] sm:h-[175px] top-[-7%] right-[-5%] sm:top-[-10%] sm:right-[-5%]"></div>
                        <div className="absolute z-[-1] opacity-0 w-[100px] h-[100px] sm:w-14 sm:h-14 rounded-full bg-[#03071115] dark:bg-[#ffffff15] top-[-5%] left-[-10%] sm:top-[-17px] sm:left-[-5%] animate-bubble-in2"></div>
                        <div className="absolute z-[-1] w-11 h-11 sm:w-5 sm:h-5 rounded-full bg-[#03071115] dark:bg-[#ffffff25] bottom-[20%] left-[-12%] sm:bottom-[20%] sm:left-[-8%] animate-bubble-in1"></div>
                        <div className="absolute z-[-1] opacity-0 w-[300px] h-[300px] sm:w-[200px] sm:h-[200px] rounded-full bg-[#03071110] dark:bg-[#ffffff15] top-[-20%] right-[-23%] sm:top-[-50px] sm:right-[-20%] animate-bubble-in4"></div>
                        <div className="absolute z-[-1] opacity-0 w-[175px] h-[175px] sm:w-[100px] sm:h-[100px] rounded-full bg-[#03071115] dark:bg-[#ffffff25] top-[40%]  right-[-10%] sm:top-[calc(60% - 10px)] sm:right-[-7%] animate-bubble-in3"></div>
                    </div>
                    <div className="flex flex-col justify-center w-[60%] xl:w-[90%] xl:max-w-[500px] sm:max-w-[400px]">
                        <p className="text-[#030711] dark:text-white text-3xl font-bold mb-4 sm:text-2xl xl:mt-10">High-Quality Products at Competitive Prices.</p>
                        <p className="text-[#98999f] text-lg leading-5 mb-4">Dedicated to providing customers with an extensive selection of products across various categories.</p>
                        <p className="text-[#030711] dark:text-white text-lg mb-2">Discover our products now</p>
                        <Link className="text-white dark:text-[#030711] bg-[#030711] dark:bg-white rounded shadow-2xl py-2 px-4 w-fit" to={"/products"} reloadDocument>Products {"->"}</Link>
                    </div>
                </div>
            </div>
            {/* values */}
            <p className="text-2xl font-bold text-[#030711] dark:text-white sm:mt-[75px]">Our Values</p>
            <div className="relative flex justify-center pt-[50px] pb-[100px] sm:py-[50px] w-[80%] max-w-[1100px]">
                <div className="flex xl:flex-col w-full xl:max-w-[550px] sm:max-w-[400px] gap-x-8 xl:gap-x-0 xl:gap-y-8">
                    <div id="card-value" className="invisible flex flex-col dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl w-full py-8 sm:py-6 px-4 xl:w-full h-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 448a32 32 0 01-18-5.57c-78.59-53.35-112.62-89.93-131.39-112.8-40-48.75-59.15-98.8-58.61-153C48.63 114.52 98.46 64 159.08 64c44.08 0 74.61 24.83 92.39 45.51a6 6 0 009.06 0C278.31 88.81 308.84 64 352.92 64c60.62 0 110.45 50.52 111.08 112.64.54 54.21-18.63 104.26-58.61 153-18.77 22.87-52.8 59.45-131.39 112.8a32 32 0 01-18 5.56z"></path></svg>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mt-6 px-6 xl:px-2 sm:hidden">We understand that the success of your business depends on the quality and affordability of your inventory.</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mt-6 px-6 xl:px-2">We've carefully curated a diverse range of products from trusted suppliers, ensuring that you have access to everything you need to satisfy your customer's needs.</p>
                    </div>
                    <div id="card-value" className="invisible flex flex-col dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl w-full py-8 sm:py-6 px-4 xl:w-full h-full">
                        <svg className="fill-[#030711e1] dark:fill-white dark:bg-[#ffffff0d] border-[#03071108] border-2 dark:border-[1px] dark:border-[#ffffff1a] rounded shadow-xl dark:shadow-none w-14 h-14 p-2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M454.65 169.4A31.82 31.82 0 00432 160h-64v-16a112 112 0 00-224 0v16H80a32 32 0 00-32 32v216c0 39 33 72 72 72h272a72.22 72.22 0 0050.48-20.55 69.48 69.48 0 0021.52-50.2V192a31.75 31.75 0 00-9.35-22.6zM176 144a80 80 0 01160 0v16H176zm192 96a112 112 0 01-224 0v-16a16 16 0 0132 0v16a80 80 0 00160 0v-16a16 16 0 0132 0z"></path></svg>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mt-6 px-6 xl:px-2 sm:hidden">Our user-friendly platform makes it easy for you to browse our extensive collection of products.</p>
                        <p className="text-center text-[#808188] dark:text-[#a3a4aa] mt-6 px-6 xl:px-2">We're constantly updating our inventory to stay ahead, so you can rest assured that you're getting access to the most up-to-date products on the market.</p>
                    </div>
                </div>
            </div>
            {/* reviews */}
            <div className="flex xl:flex-col py-[150px] sm:py-[75px] w-[80%] xl:max-w-[550px] max-w-[1100px]">
                <div>
                    <p className="text-left text-[#030711] dark:text-white text-2xl font-bold">Don't believe us?</p>
                    <p className="text-left text-lg text-[#98999f]">Heres what our customers say</p>
                </div>
                <div className="flex flex-col gap-y-6 xl:mt-4 ml-auto w-full max-w-[60%] xl:max-w-none">
                    <div id="card-value" className="invisible flex flex-col dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl w-full h-fit p-4">
                        <p className="text-[#53545a] dark:text-white text-left xl:text-[15px] sm:text-[15px] w-full">"Although I didn’t have contact with the seller, the item arrived unmanaged, working, and exactly what I was looking for. Thank you"</p>
                        <p className="text-[#808188] text-left xl:text-[15px] sm:text-[13px] w-full my-[10px]">Aileen G.</p>
                        <div className="flex w-full h-fit">
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                        </div>
                    </div>
                    <div id="card-review" className="invisible flex flex-col dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl w-full h-fit p-4">
                        <p className="text-[#53545a] dark:text-white text-left xl:text-[15px] sm:text-[15px] w-full">"Excellent product, reasonable price, prompt delivery."</p>
                        <p className="text-[#808188] text-left xl:text-[15px] sm:text-[13px] w-full my-[10px]">Caroline L.</p>
                        <div className="flex w-full h-fit">
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                        </div>
                    </div>
                    <div id="card-review" className="invisible flex flex-col dark:bg-[#0a0e18] border-[1px] border-[#03071108] dark:border-[#c8dce61a] hover:border-[#0307112a] dark:hover:border-[#c8dce63d] transition-[border-color] duration-[0.3s] ease-[ease] items-center rounded shadow-xl w-full h-fit p-4">
                        <p className="text-[#53545a] dark:text-white text-left xl:text-[15px] sm:text-[15px] w-full">"I use this all the time. The seller got it to me quickly. Thanks."</p>
                        <p className="text-[#808188] text-left xl:text-[15px] sm:text-[13px] w-full my-[10px]">Valued Customer</p>
                        <div className="flex w-full h-fit">
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                            <svg className="fill-[#030711bc] dark:fill-white h-5 w-5 xl:h-4 xl:w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z"></path></svg>
                        </div>
                    </div>
                </div> 
            </div>
        </>
    )
}