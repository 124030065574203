import ThemeButton from "./ThemeButton"
import SmallMenuButton from "./SmallMenuButton"
import ScrollToTop from "../hooks/ScrollToTop"
import { Link } from "react-router-dom"

export default function Header() {
    return (
        <header className="sticky top-0 z-20 flex items-center h-[80px] px-[35px] py-5 md:px-5 md:py-5 bg-white dark:bg-[#030711] border-b-[1px] border-[#03071125] dark:border-[#ffffff50]">
            <ScrollToTop></ScrollToTop>
            <SmallMenuButton></SmallMenuButton>
            <a className="flex items-center hover group mr-2" href="/">
                <svg className="w-7 h-7 stroke-[#030711] dark:stroke-white group-hover:animate-logo-spin" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"> <line x1="16.5" y1="9.4" x2="7.5" y2="4.21"></line> <path d="M21 16V8a2 2 0 00-1-1.73l-7-4a2 2 0 00-2 0l-7 4A2 2 0 003 8v8a2 2 0 001 1.73l7 4a2 2 0 002 0l7-4A2 2 0 0021 16z"></path> <polyline points="3.27 6.96 12 12.01 20.73 6.96"></polyline> <line x1="12" y1="22.08" x2="12" y2="12"></line> </svg>
                <p className="mx-2 text-[#030711] dark:text-white">WCitySeller</p>
            </a>
            <div className="flex items-center h-full sm:hidden">
                <Link className="text-[#98999f] pt-[4px] mx-3 text-sm" to={"/about"} reloadDocument>About</Link>
                <Link className="text-[#98999f] pt-[4px] mx-3 text-sm" to={"/products"} reloadDocument>Products</Link>
            </div>
            <ThemeButton></ThemeButton>
            <Link className="sm:hidden shadow-xl bg-[#030711] dark:bg-white text-sm text-white dark:text-[#030711] rounded px-5 py-2" to={"/contact"} reloadDocument>Contact</Link>
        </header>
    )
}