import { useState } from "react";
import { Link } from "react-router-dom";

export default function SmallMenuButton() {
    const [navModalVisible, setNavModalVisibility] = useState(false);

    document.addEventListener("click", (e) => {
        if (!navModalVisible) return;
        try {
            if (!document.getElementById("nav-button").contains(e.target) && !document.getElementById("nav-modal").contains(e.target)) {setNavModalVisibility(false);}
        } catch {};
    })

    return (
        <button id="nav-button" className="hidden relative sm:block mr-3 w-9 h-9 bg-none" onClick={e => {setNavModalVisibility(!navModalVisible)}}>
            <svg className="text-[#030711] dark:text-white" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="44" d="M102 256h308M102 176h308M102 336h308"/></svg>
            {
                navModalVisible ? 
                <div id="nav-modal" className="z-10 flex flex-col relative bg-[#f0f1f1] dark:bg-[#12161f] border-2 border-[#03071115] dark:border-[#ffffff15] rounded p-[3px] my-[10px] h-fit w-fit">
                    <Link className="text-[#030711bc] dark:text-[#ffffffbc] text-left text-[14px] rounded px-3 pr-6 py-2 w-full hover:bg-[#03071115] dark:hover:bg-[#ffffff15] duration-100" to={"/about"}>About</Link>
                    <Link className="text-[#030711bc] dark:text-[#ffffffbc] text-left text-[14px] rounded px-3 pr-6 py-2 w-full hover:bg-[#03071115] dark:hover:bg-[#ffffff15] duration-100" to={"/products"}>Products</Link>
                    <Link className="text-[#030711bc] dark:text-[#ffffffbc] text-left text-[14px] rounded px-3 pr-6 py-2 w-full hover:bg-[#03071115] dark:hover:bg-[#ffffff15] duration-100" to={"/contact"}>Contact</Link>
                </div> :
                null
            }
        </button>
    )
}